@import "../mainVariables";
@import "../mixins";

.blog-details-wrapper {
  padding: 60px 0;
  width: 100%;
  .details-main-block{
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 60px $mainPadding;
    .left-block{
      width: 48%;
      display: flex;
      flex-direction: column;
      z-index: 1000;
      position: relative;
      .block-title{
        font-size: 50px;
        font-weight: 600;
        line-height: 58px;
        letter-spacing: -0.02em;
        text-align: left;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 2;
        //-webkit-box-orient: vertical;
      }
      .block-description{
        width: 90%;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0;
        text-align: left;
        margin-top: 24px;
        color: #667085;
        position: relative;
        .arr-block{
          position: absolute;
          top: 80px;
          right: -60px;
          svg{
            width: 240px;
            height: 100px;
          }
        }
      }
      .block-date{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin-top: 48px;
        color: #667085;
      }
    }
    .right-block{
      width: 50%;
      .img-block{
        width: 100%;
        aspect-ratio: 700 / 440;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .details-info-block{
    width: 100%;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0 $mainPadding;
    .details-share-block{
      width: 34%;
      border-top: 1px solid #EAECF0;
      padding-top: 30px;
      display: flex;
      align-items: center;
      p{
        cursor: pointer;
        padding: 11px 10px;
        border: 1px solid #98A2B3;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
      }
    }
    .text{
      width: 62%;
    }
  }
  .responsive-link{
    display: none;
  }

    @media screen and (min-width: 1800px) {
      .details-main-block {
        height: 700px!important;
        .left-block{
          .block-description{
            .arr-block{
              position: absolute;
              top: 60px;
              right: -80px;
              svg{
                width: 440px;
                height: 160px;
              }
            }
          }
        }
      }
    }

  @media screen and (min-width: 1500px){
    .details-main-block{
      height: 400px;
      .left-block{
        .block-description{
          .arr-block{
            position: absolute;
            top: 60px;
            right: -140px;
            svg{
              width: 300px;
              height: 140px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1150px){
    padding: 24px 0;
    .details-main-block{
      height: unset;
      flex-direction: column;
      position: unset;
      padding: 0 $mainPadding;
      .left-block{
        width: 100%;
        .block-title{
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #101828;
        }
        .block-description{
          margin-top: 16px;
          font-size: 16px;
        }
        .block-date{
          display: none;
        }
        .arr-block{
          display: none;
        }
      }
      .right-block{
        margin-top: 30px;
        width: 100%;
        position: unset;
        padding: unset;
        .img-block{
          aspect-ratio: 343 / 240;
        }
      }
    }
    .details-info-block{
      flex-direction: column-reverse;
      margin-top: 20px;
      .details-share-block{
        width: 100%;
        border-top: unset;
        justify-content: left;
      }
      .text{
        width: 100%;
      }
    }
    .responsive-link{
      width: 90%;
      padding: 12px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color:$brandColor;
      color: white;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      margin: 0 auto;
      border-radius: 8px;
    }
  }
}